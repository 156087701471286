import { useToast } from '@chakra-ui/react';
import { ServiceType } from '@shared/constants/service';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import TagManager from '@sooro-io/react-gtm-module';
import { AddressApi } from 'api/AdressesApi';
import { DISCOUNT_CODE, VENUE_ID } from 'constants/common';
import { WIDGET_CHOOSING_LOCATION } from 'constants/googleTagManagerEvents';
import get from 'lodash.get';
import uniqBy from 'lodash.uniqby';
import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import { clearBasketUpsellsData } from 'store/slices/basketUpsells';
import {
  clearUiServicesData,
  selectUiServices,
  setEircode,
  setEircodeError,
  setFlatNo,
  setFlatNoError,
  setNewAddress,
  setSelectedServiceType,
  setShowDeliveryDetailsSection,
  setShowServicesSection,
  setStreetNumber,
} from 'store/slices/uiServices';
import { clearVenuesData, getVenues } from 'store/slices/venues';
import { selectWebsite } from 'store/slices/website';
import { clearStorageDiscountData } from 'utils/storage';

export interface IAvailableService {
  name: ServiceType | string;
  service_type: number;
}

export interface IselectVenue {
  venueId: string;
  venueName: string;
  serviceId: string;
  currency: Currency;
}

const useWidget = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search] = useSearchParams();

  const discountCode = search.get(DISCOUNT_CODE);
  const venueId = search.get(VENUE_ID);

  const {
    newAddress,
    flatNo,
    flatNoError,
    eircode,
    eircodeError,
    streetNumber,
    selectedServiceType,
    showServicesSection,
    showDeliveryDetailsSection,
  } = useAppSelector(selectUiServices);
  const { websiteData } = useAppSelector(selectWebsite);
  const { user } = useAppSelector(selectAuth);
  const bgImage: unknown = get(websiteData, ['featured'], '');

  useEffect(() => {
    storageSession.remove(StorageKeys.SERVICE_NAME);
    storageSession.remove(StorageKeys.SERVICE_TYPE);
    storage.remove(StorageKeys.ADDRESS);

    clearStorageDiscountData();
    if (discountCode) {
      storage.set(StorageKeys.COUPON_DISCOUNT_CODE, discountCode);
    }
    if (venueId) {
      storageSession.set(StorageKeys.VENUE_ID_SINGLE, venueId);
    }
  }, []);

  useEffect(() => {
    return () => {
      // clear basket upsells data
      dispatch(clearBasketUpsellsData());
      // clear selected service, address etc
      dispatch(clearUiServicesData());

      dispatch(clearVenuesData());
    };
  }, []);

  const handleSaveAddress = useCallback(() => {
    if (newAddress && user && !newAddress.id) {
      AddressApi.saveNewAddress({
        ...newAddress,
        flat_no: flatNo,
        eircode,
        formatted_address:
          (flatNo ? `${flatNo}, ` : '') +
          (newAddress.full_address ?? '') +
          (eircode ? `, ${eircode}` : ''),
      })
        .then((res) => {
          storage.set(StorageKeys.ADDRESS, JSON.stringify(res));
        })
        .catch((error: Error) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    } else {
      if (
        newAddress &&
        newAddress.id &&
        (newAddress.flat_no !== flatNo || newAddress?.eircode !== eircode)
      ) {
        AddressApi.updateAddress(newAddress.id, {
          full_address: newAddress.full_address as string,
          flat_no: flatNo,
          eircode: eircode,
        }).catch((error: Error) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
      }
      storage.set(
        StorageKeys.ADDRESS,
        JSON.stringify({
          ...newAddress,
          flat_no: flatNo,
          eircode,
          formatted_address:
            (flatNo ? `${flatNo}, ` : '') +
            (newAddress?.full_address ?? '') +
            (eircode ? `, ${eircode}` : ''),
        }),
      );
    }
    dispatch(setNewAddress(null));
  }, [newAddress, flatNo, eircode]);

  const availableServices = useMemo(() => {
    const services: Website.Service[] = get(websiteData, 'services', []);

    if (services.length) {
      const types = uniqBy(
        services.map(({ service_type }) => {
          const label = Object.values(ServiceType)[service_type];

          return {
            service_type,
            name: label === ServiceType[1] ? 'Table Service' : label,
          };
        }),
        'service_type',
      ).sort(
        (prev: IAvailableService, next: IAvailableService) =>
          prev.service_type - next.service_type,
      ); // sort to asc

      return types;
    }

    return [];
  }, [websiteData]);

  useEffect(() => {
    if (availableServices.length) {
      dispatch(setSelectedServiceType(availableServices[0].service_type));
    }
  }, [availableServices]);

  useEffect(() => {
    if (
      typeof selectedServiceType == 'number' &&
      websiteData?.venue_group?.id
    ) {
      dispatch(
        getVenues(websiteData.id, {
          service_type: selectedServiceType,
          no_page: true,
          expand: 'services,location,services.available_days,services.config',
          lat: newAddress?.lat as number,
          lng: newAddress?.lng as number,
        }),
      );
    }
  }, [websiteData, selectedServiceType, newAddress]);

  const saveToLocalStorage = useCallback(
    (serviceName: string, currency: string) => {
      storageSession.set(StorageKeys.SERVICE_NAME, serviceName);
      storage.set(StorageKeys.CURRENCY, currency);
    },
    [],
  );

  const handleSelectDeliveryVenue = useCallback(
    ({ venueId, venueName, serviceId, currency }: IselectVenue) => {
      handleSaveAddress();

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_CHOOSING_LOCATION,
          venue_name: venueName,
          service_name: 'Delivery',
        },
      });

      saveToLocalStorage('Delivery', currency);
      navigate(
        generatePath(Paths.Menu, {
          venueId,
          serviceId,
          department: null,
        }),
      );
    },
    [handleSaveAddress],
  );

  const handleSelectTableVenue = useCallback(
    ({ venueId, venueName, serviceId, currency }: IselectVenue) => {
      saveToLocalStorage('Table', currency);

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_CHOOSING_LOCATION,
          venue_name: venueName,
          service_name: 'Table',
        },
      });

      navigate(
        generatePath(Paths.Menu, {
          venueId,
          serviceId,
          department: null,
        }),
      );
    },
    [],
  );

  const handleSelectCollectionVenue = useCallback(
    ({ venueId, venueName, serviceId, currency }: IselectVenue) => {
      saveToLocalStorage('Collection', currency);

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_CHOOSING_LOCATION,
          venue_name: venueName,
          service_name: 'Collection',
        },
      });

      navigate(
        generatePath(Paths.Menu, {
          venueId,
          serviceId,
          department: null,
        }),
      );
    },
    [],
  );

  const handleSelectOtherServiceVenue = useCallback(
    ({ venueId, venueName, serviceId, currency }: IselectVenue) => {
      saveToLocalStorage('Take away', currency);

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_CHOOSING_LOCATION,
          venue_name: venueName,
          service_name: 'Take away',
        },
      });

      navigate(
        generatePath(Paths.Menu, {
          venueId,
          serviceId,
          department: null,
        }),
      );
    },
    [],
  );

  const handleSetEircode = useCallback(
    (eircode: string) => dispatch(setEircode(eircode)),
    [],
  );

  const handleSetEircodeError = useCallback(
    (eircodeError: string) => dispatch(setEircodeError(eircodeError)),
    [],
  );

  const handleSetStreetNumber = useCallback(
    (streetNumber: string) => dispatch(setStreetNumber(streetNumber)),
    [],
  );

  const handleSetFlatNo = useCallback(
    (flatNo: string) => dispatch(setFlatNo(flatNo)),
    [],
  );

  const handleSetFlatNoError = useCallback(
    (flatNoError: string) => dispatch(setFlatNoError(flatNoError)),
    [],
  );

  const handleSetNewAddress = useCallback(
    (newAddress: Partial<Addresses.Address> | null) =>
      dispatch(setNewAddress(newAddress)),
    [],
  );

  const handleSetSelectedServiceType = useCallback(
    (selectedServiceType: number | null) => {
      // delete venues what belongs to another service type
      dispatch(clearVenuesData());
      dispatch(setSelectedServiceType(selectedServiceType));
    },
    [],
  );
  const handleSetShowDeliveryDetailsSection = useCallback(
    (showDeliveryDetailsSection: boolean) =>
      dispatch(setShowDeliveryDetailsSection(showDeliveryDetailsSection)),
    [],
  );
  const handleSetShowServicesSection = useCallback(
    (showServicesSection: boolean) =>
      dispatch(setShowServicesSection(showServicesSection)),
    [],
  );

  return {
    actions: {
      setNewAddress: handleSetNewAddress,
      handleSaveAddress,
      setSelectedServiceType: handleSetSelectedServiceType,
      setShowServicesSection: handleSetShowServicesSection,
      setShowDeliveryDetailsSection: handleSetShowDeliveryDetailsSection,
      setFlatNo: handleSetFlatNo,
      setFlatNoError: handleSetFlatNoError,
      setEircode: handleSetEircode,
      setEircodeError: handleSetEircodeError,
      handleSelectDeliveryVenue,
      handleSelectTableVenue,
      handleSelectCollectionVenue,
      handleSelectOtherServiceVenue,
      handleSetStreetNumber,
    },
    data: {
      logo: websiteData?.logo || '',
      name: websiteData?.name || '',
      title: websiteData?.title || '',
      bgImage,
      availableServices,
      selectedServiceType,
      showServicesSection,
      showDeliveryDetailsSection,
      newAddress,
      flatNo,
      flatNoError,
      eircode,
      eircodeError,
      streetNumber,
    },
  };
};

export default useWidget;
